import React, { useState } from "react";
import { connect } from "react-redux";
import { setHomePageContents } from "../../../Actions/Actions";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";

const Footer = (props) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  return (
    <ErrorBoundary>
      <div className="bg2 p42">
        <div className="container_12">
          <div className="sub_form">
            <div className="grid_5">
              <i className="fa fa-envelope-o"></i>
              <h4>Subscribe to our Newsletter</h4>
            </div>
            <form id="subscribe-form" className="clearfix">
              <div className="grid_5">
                <div className="success">
                  Your subscription request
                  <br /> has been sent!
                </div>
                <fieldset>
                  <label className="email">
                    <input type="email" value="your email address" />
                    <span className="error">
                      *This is not a valid email address.
                    </span>
                  </label>
                  &nbsp;
                </fieldset>
              </div>
              <div className="grid_2">
                <a href="#" data-type="submit" className="link">
                  submit
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
      <footer id="footer" className="footer">
        <div className="container_12">
          <div className="grid_6">
            <span>
              <img src="images/logo_min.png" alt="" />
            </span>
            <div className="copy">
              <span>&copy;</span>
              <span id="copyright-year"></span>
              <span>|</span>
              <span>
                <a href="index-5.html">Privacy Policy</a>
              </span>{" "}
            </div>
          </div>
          <div className="grid_6">
            <ul className="social-links">
              <li>
                <a href="#">
                  <i className="fa fa-facebook"></i>
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fa fa-rss"></i>
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fa fa-twitter"></i>
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fa fa-google-plus"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </ErrorBoundary>
  );
};

const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
    UserProfile: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnHomePageData: (p) => {
      dispatch(setHomePageContents(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
