import "swiper/swiper.min.css";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { formatStringToDate, serverLink } from "../../Resources/Url";
import { setLoginDetails } from "../../Actions/Actions";
import icon1 from "../../Resources/page1_ico1.png";
import icon2 from "../../Resources/page1_ico2.png";
import icon3 from "../../Resources/page1_ico3.png";
import arrayShuffle from "array-shuffle";
import ErrorBoundary from "../Shared/ErrorBoundary/ErrorBoundary";
import HomeContentLoader from "../Shared/Loaders/HomeContentLoader/HomeContentLoader";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";

const Home = (props) => {
  const [currentProgram, setCurrentProgram] = useState(null);
  const [OAP, setOAP] = useState([]);

  const [oap] = useState(
    typeof props?.HomePageData.oap !== "undefined"
      ? arrayShuffle(props?.HomePageData.oap)
      : []
  );

  const [programs] = useState(
    typeof props?.HomePageData.programs !== "undefined"
      ? arrayShuffle(props?.HomePageData.programs)
      : []
  );

  const [news] = useState(
    typeof props.HomePageData.news !== "undefined"
      ? props.HomePageData.news
      : []
  );

  const [newsImage] = useState(
    typeof props.HomePageData.newsImage !== "undefined"
      ? props.HomePageData.newsImage
      : []
  );

  const [newsCategory] = useState(
    typeof props.HomePageData.newsCategory !== "undefined"
      ? props.HomePageData.newsCategory
      : []
  );

  const filteredOAPs = oap.filter((x) => x.StationId === Number(1));
  const filteredPrograms = programs
    .filter((x) => x.StationId === Number(1))
    .slice(0, 6);

  useEffect(() => {
    if (Object.keys(filteredPrograms).length > 0) {
      const checkProgramMatch = () => {
        const currentTime = new Date();

        const matchingProgram = programs.find((program) => {
          const startTime = new Date();
          const [startHour, startMinute] = program.StartTime.split(":");
          startTime.setHours(startHour, startMinute, 0, 0);

          const endTime = new Date();
          const [endHour, endMinute] = program.EndTime.split(":");
          endTime.setHours(endHour, endMinute, 0, 0);

          return currentTime >= startTime && currentTime <= endTime;
        });

        if (matchingProgram) {
          setCurrentProgram(matchingProgram);
        }
      };

      checkProgramMatch(); // Initial check on component mount

      const interval = setInterval(checkProgramMatch, 60000); // Check every minute

      return () => {
        clearInterval(interval);
      };
    }
    if (Object.keys(filteredPrograms).length < 1) {
      const matchingProgram = filteredPrograms.find(
        (r) => r.ProgramId === Number(1)
      );

      setCurrentProgram(matchingProgram);
    }
  }, []);

  useEffect(() => {
    setTimeout(async () => {
      setOAP(filteredOAPs);
    }, 1000);
  }, [filteredOAPs, filteredPrograms, OAP]);

  if (Object.keys(OAP).length === 0) {
    return <HomeContentLoader />;
  }

  console.log("filteredPrograms", filteredPrograms);

  return (
    <ErrorBoundary>
      <div id="content">
        <div className="container_12 p100">
          <div className="grid_4 wrap">
            <div className="thumb">
              <div className="circle">
                <img src={icon1} alt="" />
              </div>
              <h3
                style={{
                  fontFamily: "Open Sans, sans-serif",
                  fontWeight: "300",
                  textTransform: "uppercase",
                  display: "block",
                  fontSize: "35px",
                  lineHeight: "30px",
                }}
              >
                General Entertainment
              </h3>
              <h4>
                <a href="#" className="link1">
                  cet inger lot aliiqtes.
                </a>
              </h4>
              <p>
                Enyimba 94.3fm provides entertainment through music shows, local
                artist interviews, cultural programs, and more, engaging and
                entertaining their audience alongside their main focus on
                sports, politics, and current affairs.
              </p>
              <a href="#" className="link2">
                more info
              </a>
            </div>
          </div>
          <div className="grid_4 wrap">
            <div className="thumb">
              <div className="circle">
                <img src={icon2} alt="" />
              </div>
              <h3
                style={{
                  fontFamily: "Open Sans, sans-serif",
                  fontWeight: "300",
                  textTransform: "uppercase",
                  display: "block",
                  fontSize: "35px",
                  lineHeight: "30px",
                }}
              >
                Culture & Education
              </h3>
              <h4>
                <a href="#" className="link1">
                  {" "}
                  cet inger lot aliiqtes.
                </a>
              </h4>
              <p>
                Enyimba 94.3fm delivers timely sports, politics, and current
                affairs updates through news bulletins, analysis, interviews,
                and discussions, keeping listeners informed about Enyimba city
                and beyond. We do more than aforementioned.
              </p>
              <a href="#" className="link2">
                more info
              </a>
            </div>
          </div>
          <div className="grid_4">
            <div className="thumb">
              <div className="circle">
                <img src={icon3} alt="" />
              </div>
              <h3
                style={{
                  fontFamily: "Open Sans, sans-serif",
                  fontWeight: "300",
                  textTransform: "uppercase",
                  display: "block",
                  fontSize: "35px",
                  lineHeight: "30px",
                }}
              >
                News & Current Affairs
              </h3>
              <h4>
                <a href="#" className="link1">
                  cet inger lot aliiqtes.
                </a>
              </h4>
              <p>
                Enyimba 94.3fm highlights local culture, traditions, and
                heritage through programs, while also offering educational
                content, interviews with scholars, discussions on educational
                topics, and initiatives to promote learning and development in
                Enyimba city.
              </p>
              <a href="#" className="link2">
                more info
              </a>
            </div>
          </div>
        </div>

        {/*ADVERT START*/}
        <div
          className="bg1 p93"
          style={{
            backgroundColor: "rgba(196,190,190,0.2)",
          }}
        >
          <div className="container_12">
            <div className="grid_12">
              {isMobile ? (
                <div className="info_block">
                  <h3
                    className="col2"
                    style={{
                      fontSize: "13px",
                    }}
                  >
                    {" "}
                    DO YOU WANT AN ADVERT SPACE ON <br />
                    Enyimba FM website? <Link to="/contact">
                      click here
                    </Link>{" "}
                    <br />
                  </h3>
                  <h4
                    style={{
                      fontSize: "15px",
                    }}
                  >
                    The station that brings you intriguing programs
                  </h4>
                  <a href="https://live.enyinba.com" target="_blank">
                    <i className="fa fa-play-circle"></i>
                  </a>
                </div>
              ) : (
                <div className="info_block">
                  <h3 className="col2">
                    {" "}
                    DO YOU WANT AN ADVERT SPACE ON <br />
                    Enyimba FM website? <Link to="/contact">
                      click here
                    </Link>{" "}
                    <br />
                  </h3>
                  <h4>The station that brings you intriguing programs</h4>
                  <a href="https://live.enyinba.com" target="_blank">
                    <i className="fa fa-play-circle"></i>
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
        {/*ADVERT END*/}

        {/*TOPS NEWS START*/}
        <div
          className="p93 bg1"
          style={{
            cursor: "pointer",
          }}
        >
          <div className="container_12">
            <div className="grid_12 center">
              <h3 className="col1">Top News</h3>
              <div>
                {news && Object.keys(news).length ? (
                  <>
                    {news.map((item, index) => {
                      return (
                        <Link to={`/news/${item.Slug}`}>
                          <div className="grid_4 alpha block1 mb46">
                            {/*<h5>{item.ProgramName}</h5>*/}
                            <h4
                              style={{
                                // textAlign: "justify",
                                marginLeft: "10px",
                                color: "black",
                                fontSize: "18px",
                                textAlign: "justify",
                              }}
                            >
                              <h
                                style={{
                                  fontSize: "20px",
                                }}
                              >
                                {item.Title}
                              </h>{" "}
                            </h4>

                            {/*<h4>*/}
                            {/*  <a href="#" className="link1">*/}
                            {/*    {*/}
                            {/*      oap*/}
                            {/*        .filter(*/}
                            {/*          (i) => i.OAPId === Number(item.OAPId)*/}
                            {/*        )*/}
                            {/*        .map((x) => (*/}
                            {/*          <>*/}
                            {/*            <Link to={`/staff/${x.Slug}`}>*/}
                            {/*              {x.FullName}*/}
                            {/*            </Link>*/}
                            {/*          </>*/}
                            {/*        ))[0]*/}
                            {/*    }*/}
                            {/*  </a>*/}
                            {/*</h4>*/}

                            {/*<p*/}
                            {/*  style={{*/}
                            {/*    textAlign: "justify",*/}
                            {/*    marginLeft: "10px",*/}
                            {/*    color: "black",*/}
                            {/*    fontSize: "16px",*/}
                            {/*  }}*/}
                            {/*>*/}
                            {/*  {item.Title}*/}
                            {/*</p>*/}
                            <img
                              src="https://dummyimage.com/400x300"
                              alt="img"
                              className="grid_4"
                            />
                          </div>
                        </Link>
                      );
                    })}
                  </>
                ) : (
                  <>
                    <p>There is no available news!</p>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {/*TOPS NEWS END*/}

        <div
          className="bg1 p93"
          style={{
            backgroundColor: "rgba(196,190,190,0.2)",
          }}
        >
          <div className="container_12">
            <div className="grid_12">
              <div className="info_block">
                <h3 className="col2">
                  Captivating moments on Enyimba FM <br />
                  <span className="col1">radio station!</span>
                </h3>
                <h4>The station that brings you intriguing programs</h4>
                <a href="https://live.enyinba.com" target="_blank">
                  <i className="fa fa-play-circle"></i>
                </a>
              </div>
            </div>
          </div>
        </div>

        {/*PROGRAM START*/}
        <div
          className="p93 bg1"
          style={{
            cursor: "pointer",
          }}
        >
          <div className="container_12">
            <div className="grid_12 center">
              <h3 className="col1">Our Programmes</h3>
              <div>
                {filteredPrograms && Object.keys(filteredPrograms).length ? (
                  <>
                    {filteredPrograms.map((item, index) => {
                      return (
                        <Link to={`/program/${item.Slug}`}>
                          <div className="grid_4 alpha block1 mb46">
                            {/*<h5>{item.ProgramName}</h5>*/}
                            <h4
                              style={{
                                // textAlign: "justify",
                                marginLeft: "10px",
                                color: "black",
                                fontSize: "18px",
                              }}
                            >
                              <h
                                style={{
                                  fontSize: "20px",
                                }}
                              >
                                {item.ProgramName}
                              </h>{" "}
                              <br />
                              From {item.StartTime} To {item.StartTime} <br />{" "}
                              Every {item.Day}
                            </h4>

                            <h4>
                              <a href="#" className="link1">
                                {
                                  oap
                                    .filter(
                                      (i) => i.OAPId === Number(item.OAPId)
                                    )
                                    .map((x) => (
                                      <>
                                        <Link to={`/staff/${x.Slug}`}>
                                          {x.FullName}
                                        </Link>
                                      </>
                                    ))[0]
                                }
                              </a>
                            </h4>
                            {/*<p*/}
                            {/*  style={{*/}
                            {/*    textAlign: "justify",*/}
                            {/*    marginLeft: "10px",*/}
                            {/*    color: "black",*/}
                            {/*    fontSize: "16px",*/}
                            {/*  }}*/}
                            {/*>*/}
                            {/*  {item.ShortDescription}*/}
                            {/*</p>*/}
                            <img
                              src="https://dummyimage.com/400x300"
                              alt="img"
                              className="grid_4"
                            />
                          </div>
                        </Link>
                      );
                    })}
                  </>
                ) : (
                  <>
                    <p>There is no available program!</p>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {/*PROGRAM END*/}
      </div>
    </ErrorBoundary>
  );
};

const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
    UserProfile: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnLoginDetails: (p) => {
      dispatch(setLoginDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
