import React, { useState } from "react";

const styles = {
  radioPlayer: {
    maxWidth: "300px",
    margin: "0 auto",
    padding: "20px",
    textAlign: "center",
    backgroundColor: "#f5f5f5",
    borderRadius: "10px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  },
  heading: {
    color: "#333",
  },
  playButton: {
    display: "inline-block",
    padding: "10px 20px",
    fontSize: "16px",
    fontWeight: "bold",
    textTransform: "uppercase",
    color: "#fff",
    backgroundColor: "#007bff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
  },
  playing: {
    backgroundColor: "#dc3545",
  },
};
const RadioPlayer = () => {
  const [isPlaying, setIsPlaying] = useState(false);

  const togglePlay = () => {
    setIsPlaying(!isPlaying);
  };

  return (
    <div style={styles.radioPlayer}>
      <h2 style={styles.heading}>Live Radio Player</h2>
      <button
        style={{
          ...styles.playButton,
          ...(isPlaying ? styles.playing : {}),
        }}
        onClick={togglePlay}
      >
        {isPlaying ? "Pause" : "Play"}
      </button>
      <audio
        src="https://ec1.everestcast.host:4625/stream"
        controls
        autoPlay={isPlaying}
      >
        Your browser does not support the audio element.
      </audio>
    </div>
  );
};

export default RadioPlayer;
