import React from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { setLoginDetails } from "../../Actions/Actions";
import { connect } from "react-redux";
import Home from "../Home/Home";
import Footer from "../Shared/Footer/Footer";
import Results from "../Results/Results";
import Header from "../Shared/Header/Header";
import Slider from "../Shared/Slider/Slider";
import OAPs from "../OAP/OAPS";
import OAPDetails from "../OAPDetails/OAPDetails";
import Player from "../Player/Player";
import ProgramDetails from "../ProgramDetails/ProgramDetails";
import NewsDetails from "../NewsDetails/NewsDetails";
import Schedule from "../Schedule/Schedule";
import News from "../News/News";
import Contact from "../Contact/Contact";

function Routing() {
  const location = useLocation();
  const pathSegments = location.pathname
    .split("/")
    .filter((segment) => segment !== "");
  const firstItem = pathSegments[0];
  return (
    <>
      {firstItem && Object.keys(firstItem.includes("oap")) ? (
        <>
          <Header />
          <Routes>
            <Route path="/home" element={<Home />} />
            <Route path="/staff/:slug" element={<OAPDetails />} />
            <Route path="/program/:slug" element={<ProgramDetails />} />
            <Route path="/news/:slug" element={<NewsDetails />} />
            <Route path="/results" element={<Results />} />
            <Route path="/schedule" element={<Schedule />} />
            <Route path="/news" element={<News />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/live" element={<Player />} />
            <Route path="/oap" element={<OAPs />} />
            <Route path="*" element={<Home />} />
          </Routes>
          <Footer />
        </>
      ) : (
        <>
          <Header />
          <Slider />
          <Routes>
            <Route path="/home" element={<Home />} />
            <Route path="/staff/:slug" element={<OAPDetails />} />
            <Route path="/live" element={<Player />} />
            <Route path="/results" element={<Results />} />
            <Route path="/schedule" element={<Schedule />} />
            <Route path="/news" element={<News />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/oap" element={<OAPs />} />
            <Route path="*" element={<Home />} />
          </Routes>
          <Footer />
        </>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
    UserProfile: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnLoginDetails: (p) => {
      dispatch(setLoginDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Routing);
