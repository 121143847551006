import "swiper/swiper.min.css";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { serverLink } from "../../Resources/Url";
import { setLoginDetails } from "../../Actions/Actions";
import axios from "axios";
import ErrorBoundary from "../Shared/ErrorBoundary/ErrorBoundary";
import page2_img1 from "../../Resources/page2_img1.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import HomeContentLoader from "../Shared/Loaders/HomeContentLoader/HomeContentLoader";
import arrayShuffle from "array-shuffle";

const OAPDetails = (props) => {
  const navigate = useNavigate();
  const [OAP, serOAP] = useState([]);
  const [oap] = useState(
    typeof props.HomePageData.oap !== "undefined" ? props.HomePageData.oap : []
  );

  const [programsList_] = useState(
    typeof props?.HomePageData.programs !== "undefined"
      ? arrayShuffle(props?.HomePageData.programs)
      : []
  );

  const url = window.location.pathname;
  const splitUrl = url.split("-");
  const lastCharacter = splitUrl.pop();

  const sendData = {
    OAPId: Number(lastCharacter),
  };

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.post(`${serverLink}shared/oap`, sendData);

        serOAP(response.data);
      } catch (error) {
        navigate("/");
        console.error("Error fetching product:", error);
      }
    };

    fetchProduct().then((r) => {});
  }, [lastCharacter]);

  const oapList =
    Array.isArray(oap) &&
    oap.length > 0 &&
    oap.filter((x) => x.OAPId === Number(lastCharacter));

  const filteredOAPPrograms =
    Array.isArray(programsList_) &&
    programsList_.length > 0 &&
    programsList_.filter((x) => x.OAPId === Number(oapList[0].OAPId));

  if (OAP && Object.keys(OAP).length === 0) {
    return <HomeContentLoader />;
  }

  return (
    <ErrorBoundary>
      <div id="content">
        <div className="p146">
          <div className="container_12">
            <div className="grid_12">
              <Link to={`/program/${oapList.Slug}`}>
                <h3>{oapList[0].FullName}</h3>
              </Link>
            </div>
          </div>
          <div className="container_12">
            <div className="grid_3">
              <div className="image">
                <img
                  src={`${serverLink}public/uploads/oaps/${oapList[0].Passport}`}
                  alt="image"
                />
              </div>
            </div>
            <div className="grid_9">
              <div className="block">
                <h4>
                  <p style={{ color: "black" }}>On-Air Personality Overview</p>
                </h4>
                <p>{oapList[0].Description} </p>
                <p>{oapList[0].Biography}. </p>
                <hr />
                <div className="grid_6">
                  <ul
                    style={{
                      display: "flex",
                      marginTop: "20px",
                      padding: "0",
                      listStyle: "none",
                      alignContent: "center",
                    }}
                  >
                    <strong
                      style={{
                        marginRight: "20px",
                      }}
                    >
                      OAP Social Media:
                    </strong>{" "}
                    <li
                      style={{
                        marginRight: "20px",
                      }}
                    >
                      <a href="#">
                        <i className="fa fa-facebook size2"></i>
                      </a>
                    </li>
                    <li
                      style={{
                        marginRight: "20px",
                      }}
                    >
                      <a href="#">
                        <i className="fa fa-instagram size2"></i>
                      </a>
                    </li>
                    <li
                      style={{
                        marginRight: "20px",
                      }}
                    >
                      <a href="#">
                        <i className="fa fa-twitter size2"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="p93 bg1"
          style={{
            cursor: "pointer",
          }}
        >
          <div className="container_12">
            <div className="grid_12 center">
              <h3 className="col1">OAP Programes</h3>
              <div>
                {filteredOAPPrograms &&
                Object.keys(filteredOAPPrograms).length ? (
                  <>
                    {filteredOAPPrograms.map((item, index) => {
                      return (
                        <Link to={`/program/${item.Slug}`}>
                          <div className="grid_4 alpha block1 mb46">
                            <h5>{item.ProgramName}</h5>
                            <h4>
                              <a href="#" className="link1">
                                {
                                  oap
                                    .filter(
                                      (i) => i.OAPId === Number(item.OAPId)
                                    )
                                    .map((x) => (
                                      <>
                                        <Link to={`/staff/${x.Slug}`}>
                                          {x.FullName}
                                        </Link>
                                      </>
                                    ))[0]
                                }
                              </a>
                            </h4>
                            <p
                              style={{
                                textAlign: "justify",
                                marginLeft: "10px",
                                color: "black",
                                fontSize: "16px",
                              }}
                            >
                              {item.ShortDescription}
                            </p>
                            <img
                              src="https://dummyimage.com/400x300"
                              alt="img"
                              className="grid_4"
                            />
                          </div>
                        </Link>
                      );
                    })}
                  </>
                ) : (
                  <>
                    <p>There is no available program!</p>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
    UserProfile: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnLoginDetails: (p) => {
      dispatch(setLoginDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OAPDetails);
