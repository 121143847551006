import "swiper/swiper.min.css";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { serverLink } from "../../Resources/Url";
import { setLoginDetails } from "../../Actions/Actions";
import ErrorBoundary from "../Shared/ErrorBoundary/ErrorBoundary";
import { Link } from "react-router-dom";
import HomeContentLoader from "../Shared/Loaders/HomeContentLoader/HomeContentLoader";
import arrayShuffle from "array-shuffle";

const OAPs = (props) => {
  const [OAP, setOAP] = useState([]);
  const [oap] = useState(
    typeof props?.HomePageData.oap !== "undefined"
      ? arrayShuffle(props?.HomePageData.oap)
      : []
  );

  const [programs] = useState(
    typeof props?.HomePageData.programs !== "undefined"
      ? arrayShuffle(props?.HomePageData.programs)
      : []
  );

  const filteredOAPs = oap.filter((x) => x.StationId === Number(1));
  const filteredPrograms = programs.filter((x) => x.StationId === Number(1));

  useEffect(() => {
    setTimeout(async () => {
      setOAP(filteredOAPs);
    }, 1000);
  });

  if (Object.keys(OAP).length === 0) {
    return <HomeContentLoader />;
  }

  return (
    <ErrorBoundary>
      <div id="content">
        <br />
        <div className="p93 bg1">
          <div className="container_12">
            <div className="grid_12 center">
              <h3 className="col1" style={{ color: "black" }}>
                <strong style={{ color: "#31353d" }}>
                  our On Air Personality
                </strong>
              </h3>

              {filteredOAPs &&
                filteredOAPs.map((item, index) => {
                  return (
                    <>
                      <div className="grid_3 alpha wrap">
                        <Link to={`/staff/${item.Slug}`}>
                          <div className="thumb1">
                            <div className="image">
                              <img
                                src={`${serverLink}public/uploads/oaps/${item.Passport}`}
                                alt="image"
                              />
                            </div>
                            <h4>
                              <a
                                href="#"
                                className="link1"
                                style={{ color: "#31353d" }}
                              >
                                <strong> {item.FullName}</strong>
                              </a>
                            </h4>
                            <p style={{ color: "#31353d" }}>
                              {item.Description}
                            </p>
                          </div>
                        </Link>
                      </div>
                    </>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="bg2 p93">
          <div className="container_12">
            <div className="grid_8">
              <h3>why choose us?</h3>
              <div className="grid_4 alpha block1 mb46">
                <div className="numb">01</div>
                <h5>sed dolor Pelentesque</h5>
                <h4>
                  <a href="#" className="link1">
                    erte laciniate ultriciet.
                  </a>
                </h4>
                <p>
                  Dolor nunc vule putateulr ips dol consec.Donec semp ertet
                  laciniate ultricie upien disse comete dolo lectus fgilla
                  itollicil tua ludin dolor. nec met{" "}
                </p>
              </div>
              <div className="grid_4 omega block1 mb46">
                <div className="numb">02</div>
                <h5>Proin dictum velit</h5>
                <h4>
                  <a href="#" className="link1">
                    erte laciniate ultriciet.
                  </a>
                </h4>
                <p>
                  Dolor nunc vule putateulr ips dol consec.Donec semp ertet
                  laciniate ultricie upien disse comete dolo lectus fgilla
                  itollicil tua ludin dolor. nec met{" "}
                </p>
              </div>
              <div className="grid_8 alpha">
                <div className="grid_4 alpha block1 wrap">
                  <div className="numb">03</div>
                  <h5>Aliquam con mentum </h5>
                  <h4>
                    <a href="#" className="link1">
                      erte laciniate ultriciet.
                    </a>
                  </h4>
                  <p>
                    Dolor nunc vule putateulr ips dol consec.Donec semp ertet
                    laciniate ultricie upien disse comete dolo lectus fgilla
                    itollicil tua ludin dolor. nec met{" "}
                  </p>
                </div>
                <div className="grid_4 omega block1 wrap">
                  <div className="numb">04</div>
                  <h5>Donec eget tellus </h5>
                  <h4>
                    <a href="#" className="link1">
                      erte laciniate ultriciet.
                    </a>
                  </h4>
                  <p>
                    Dolor nunc vule putateulr ips dol consec.Donec semp ertet
                    laciniate ultricie upien disse comete dolo lectus fgilla
                    itollicil tua ludin dolor. nec met{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="grid_4">
              <h3>Latest new?</h3>
              <ul className="date_list">
                <li>
                  <div className="grid_1 alpha">
                    <time dateTime="2014-02-10">
                      10<span>feb</span>
                    </time>
                  </div>
                  <div className="grid_3 omega">
                    <div className="pl">
                      <h4>Donec facilisi ulet</h4>
                      <h6>
                        <a href="#" className="link1">
                          Lorem Ipsum dolore
                        </a>
                      </h6>
                      <p>
                        Morbi tempor quam massadipu iscing ut curs congue
                        pentesqu
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="grid_1 alpha">
                    <time dateTime="2014-02-12">
                      12<span>feb</span>
                    </time>
                  </div>
                  <div className="grid_3 omega">
                    <div className="pl">
                      <h4>Donec facilisi ulet</h4>
                      <h6>
                        <a href="#" className="link1">
                          Lorem Ipsum dolore
                        </a>
                      </h6>
                      <p>
                        Morbi tempor quam massadipu iscing ut curs congue
                        pentesqu
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="grid_1 alpha">
                    <time dateTime="2014-02-14">
                      14<span>feb</span>
                    </time>
                  </div>
                  <div className="grid_3 omega">
                    <div className="pl">
                      <h4>Donec facilisi ulet</h4>
                      <h6>
                        <a href="#" className="link1">
                          Lorem Ipsum dolore
                        </a>
                      </h6>
                      <p>
                        Morbi tempor quam massadipu iscing ut curs congue
                        pentesqu
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
    UserProfile: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnLoginDetails: (p) => {
      dispatch(setLoginDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OAPs);
