import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setHomePageContents } from "../../../Actions/Actions";
import logole from "../../../Resources/logole.png";
import logoeny from "../../../Resources/logoeny.png";
import logorose from "../../../Resources/logorose.png";
import loudSpeaker from "../../../Resources/loud_speaker.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";

const Header = (props) => {
  const location = useLocation();
  const pathSegments = location.pathname
    .split("/")
    .filter((segment) => segment !== "");
  const firstItem = pathSegments[0];
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [oap] = useState(
    typeof props.HomePageData.oap !== "undefined" ? props.HomePageData.oap : []
  );

  const [votes] = useState(
    typeof props.HomePageData.votes !== "undefined"
      ? props.HomePageData.votes
      : []
  );

  const [stations] = useState(
    typeof props.HomePageData.stations !== "undefined"
      ? props.HomePageData.stations
      : []
  );

  const [programs] = useState(
    typeof props.HomePageData.programs !== "undefined"
      ? props.HomePageData.programs
      : []
  );

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const filteredStations = stations.filter((r) => r.StationId === Number(1));

  const filteredPrograms = programs.filter((r) => r.StationId === Number(1));

  const navbarStyles = {
    zIndex: 9999, // Ensure the dropdown is on top of other elements
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "white",
    background: "rgba(189,133,6,0.45)",
    padding: "10px",
    // transform: "translateY(20px)",
    marginTop: "30px", // Add marginTop to align with other items
  };

  const navStyles = {
    color: "black",
    listStyle: "none",
    // marginTop: "30px",
    display: isMobileMenuOpen ? "flex" : "none",
    flexDirection: "column",
    alignItems: "flex-start", // Align menu items to the left
    background: "white", // Gray background color for the mobile dropdown
    height: "auto", // Expand the dropdown height to fit all items
    position: "absolute",
    width: "100%",
    zIndex: 9999, // Ensure the dropdown is on top of other elements
  };

  const listItemStyles = {
    color: "black",
    zIndex: 9999, // Ensure the dropdown is on top of other elements
    margin: "10px 0",
    padding: "5px 10px", // Adjust the padding for spacing between items
  };

  const mobileMenuIconStyles = {
    color: "black",
    zIndex: 9999, // Ensure the dropdown is on top of other elements
    fontSize: "24px",
    cursor: "pointer",
  };

  return (
    <ErrorBoundary>
      <header id="header">
        <div className="container_12">
          <div className="grid_12">
            <h1>
              <Link to="/">
                <img src={logoeny} alt="" width="300" />
                {/*<img src={logorose} alt="" width="220" />*/}
                {/*<img src={logole} alt="" width="200" />*/}
                {/*<img src={logoeny} alt="" width="300" />*/}
                {/* <img src={logorose} alt="" width="250" /> Rose */}
                {/* <img src={mainLogo} alt="" width="210" /> Legend */}
              </Link>
            </h1>
            <div>
              <a
                href="https://live.enyinba.com/1"
                target="_blank"
                className="button"
              >
                <img src={loudSpeaker} alt="" />
                <span>Live</span>
                <br />
                {/*{currentProgram ? (*/}
                {/*  <div>*/}
                {/*    <small*/}
                {/*      style={{*/}
                {/*        fontSize: "12px",*/}
                {/*      }}*/}
                {/*    >*/}
                {/*      {currentProgram.ProgramName}*/}
                {/*    </small>{" "}*/}
                {/*  </div>*/}
                {/*) : (*/}
                {/*  <p>No program matching the current time.</p>*/}
                {/*)}*/}
              </a>
            </div>
          </div>
          <div className="grid_12">
            {isMobile ? (
              <>
                <nav className="navbar navbar-expand-lg navbar-light bg-light">
                  <div className="container-fluid" style={navbarStyles}>
                    <a
                      className="navbar-brand"
                      href="#"
                      style={{ color: "white" }}
                    >
                      Menu
                    </a>
                    <button
                      className="navbar-toggler"
                      type="button"
                      onClick={toggleMobileMenu}
                      style={mobileMenuIconStyles}
                    >
                      <i
                        className={`bi bi-${isMobileMenuOpen ? "x" : "list"}`}
                      ></i>
                    </button>
                  </div>
                  {isMobileMenuOpen && (
                    <div className="container-fluid" style={navStyles}>
                      <ul className="navbar-nav">
                        <li className="nav-item" style={listItemStyles}>
                          <Link
                            onClick={closeMobileMenu}
                            className="nav-link"
                            to="/"
                            style={{
                              color: "black",
                            }}
                          >
                            Home
                          </Link>
                        </li>
                        <li className="nav-item" style={listItemStyles}>
                          <Link
                            onClick={closeMobileMenu}
                            className="nav-link"
                            to="/oap"
                            style={{
                              color: "black",
                            }}
                          >
                            OAPs
                          </Link>
                        </li>
                        <li className="nav-item" style={listItemStyles}>
                          <Link
                            onClick={closeMobileMenu}
                            className="nav-link"
                            to="/schedule"
                            style={{
                              color: "black",
                            }}
                          >
                            Schedule
                          </Link>
                        </li>
                        <li className="nav-item" style={listItemStyles}>
                          <Link
                            className="nav-link"
                            to="/news"
                            onClick={closeMobileMenu}
                            style={{
                              color: "black",
                            }}
                          >
                            News
                          </Link>
                        </li>
                        <li className="nav-item" style={listItemStyles}>
                          <Link
                            className="nav-link"
                            to="/contact"
                            onClick={closeMobileMenu}
                            style={{
                              color: "black",
                            }}
                          >
                            Contact Us
                          </Link>
                        </li>
                      </ul>
                    </div>
                  )}
                </nav>
              </>
            ) : (
              <nav>
                <ul className="sf-menu">
                  <li
                    className={
                      firstItem && firstItem.includes("/") ? `current` : ""
                    }
                  >
                    <Link to="/">Home</Link>
                  </li>
                  <li
                    className={
                      firstItem && firstItem.includes("oap") ? `current` : ""
                    }
                  >
                    <Link to="/oap">OAPs</Link>
                  </li>
                  <li
                    className={
                      firstItem && firstItem.includes("schedule")
                        ? `current`
                        : ""
                    }
                  >
                    <Link to="/schedule">Schedule</Link>
                  </li>
                  <li
                    className={
                      firstItem && firstItem.includes("news") ? `current` : ""
                    }
                  >
                    <Link to="/news">News</Link>
                  </li>
                  <li
                    className={
                      firstItem && firstItem.includes("contact")
                        ? `current`
                        : ""
                    }
                  >
                    <Link to="/contact">Contact</Link>
                  </li>
                </ul>
              </nav>
            )}
          </div>
        </div>
      </header>
      <div className="clear"></div>
    </ErrorBoundary>
  );
};

const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
    UserProfile: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnHomePageData: (p) => {
      dispatch(setHomePageContents(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
