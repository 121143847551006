import "swiper/swiper.min.css";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { formatStringToDate, serverLink } from "../../Resources/Url";
import { setLoginDetails } from "../../Actions/Actions";
import axios from "axios";
import ErrorBoundary from "../Shared/ErrorBoundary/ErrorBoundary";
import page2_img1 from "../../Resources/page2_img1.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import HomeContentLoader from "../Shared/Loaders/HomeContentLoader/HomeContentLoader";
import icon1 from "../../Resources/page1_ico1.png";
import icon2 from "../../Resources/page1_ico2.png";
import icon3 from "../../Resources/page1_ico3.png";
import arrayShuffle from "array-shuffle";

const ProgramDetails = (props) => {
  const navigate = useNavigate();
  const [programs, setPrograms] = useState([]);
  const [oap] = useState(
    typeof props.HomePageData.oap !== "undefined" ? props.HomePageData.oap : []
  );

  const [programsList_] = useState(
    typeof props?.HomePageData.programs !== "undefined"
      ? arrayShuffle(props?.HomePageData.programs)
      : []
  );

  const url = window.location.pathname;
  const splitUrl = url.split("-");
  const lastCharacter = splitUrl.pop();

  const sendData = {
    ProgramId: Number(lastCharacter),
  };

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.post(
          `${serverLink}shared/program`,
          sendData
        );

        setPrograms(response.data);
      } catch (error) {
        navigate("/");
        console.error("Error fetching product:", error);
      }
    };

    fetchProduct().then((r) => {});
  }, [lastCharacter]);

  const programsList =
    programsList_ &&
    programsList_.length > 0 &&
    programsList_.filter((x) => x.ProgramId === Number(lastCharacter));

  if (programs && Object.keys(programs).length === 0) {
    return <HomeContentLoader />;
  }

  return (
    <ErrorBoundary>
      <div id="content">
        <div className="container_12 p100">
          <div className="grid_4 wrap">
            <>
              <div
                className="grid_12 mb46"
                style={{
                  textAlign: "justify",
                }}
              >
                <h2
                  style={{
                    fontSize: "35px",
                    marginLeft: "10px",
                    color: "black",
                  }}
                >
                  {programs.ProgramName} - From {programs.StartTime} To{" "}
                  {programs.EndTime} Every {programs.Day}
                </h2>
                <h4
                  style={{
                    fontSize: "16px",
                    marginLeft: "10px",
                    color: "black",
                    fontWeight: "400px",
                  }}
                >
                  <a href="#" className="link1">
                    {
                      oap
                        .filter((i) => i.OAPId === Number(programs.OAPId))
                        .map((x) => (
                          <Link key={x.StaffId} to={`/staff/${x.Slug}`}>
                            {x.FullName}
                          </Link>
                        ))[0]
                    }
                  </a>{" "}
                  {/*{formatStringToDate(news.CreatedDate)}*/}
                </h4>
                <p style={{ fontSize: "16px", marginLeft: "10px" }}>
                  Dolor nunc vule putateulr ips dol consec.Donec semp ertet
                  laciniate ultricie upien disse comete dolo lectus fgilla
                  itollicil tua ludin dolor. nec met{" "}
                </p>
                <img
                  src="https://dummyimage.com/400x300"
                  alt="img"
                  className="grid_4"
                  style={{ width: "100%", height: "auto" }}
                />
              </div>
            </>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
    UserProfile: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnLoginDetails: (p) => {
      dispatch(setLoginDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProgramDetails);
