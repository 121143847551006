import React, { useState, useEffect } from "react";
import { setLoginDetails } from "../../Actions/Actions";
import { connect } from "react-redux";
import { serverLink } from "../../Resources/Url";

import ReactTable from "react-table-6";
import "react-table-6/react-table.css";

const Results = (props) => {
  // const [voteData, setVoteData] = useState([]);

  const [voteData] = useState(
    typeof props.HomePageData.votes !== "undefined"
      ? props.HomePageData.votes
      : []
  );

  const calculateTopThreeByStation = (stationId) => {
    const stationVoteData = voteData.filter(
      (result) => result.StationId === stationId
    );
    stationVoteData.sort((a, b) => b.VoteCount - a.VoteCount);

    const topThree = stationVoteData.slice(0, 3);

    return topThree;
  };

  const calculateTopThreeCombined = () => {
    const staffVoteCounts = voteData.reduce((counts, current) => {
      if (counts[current.StaffId]) {
        counts[current.StaffId] += current.VoteCount;
      } else {
        counts[current.StaffId] = current.VoteCount;
      }
      return counts;
    }, {});

    const staffVoteData = Object.keys(staffVoteCounts).map((staffId) => ({
      StaffId: staffId,
      VoteCount: staffVoteCounts[staffId],
    }));

    staffVoteData.sort((a, b) => b.VoteCount - a.VoteCount);

    const topThree = staffVoteData.slice(0, 3);

    return topThree;
  };

  const calculateTopStaffPerQuestion = () => {
    const topStaffPerQuestion = {};

    voteData.forEach((result) => {
      const { QuestionId, StaffId, StaffName, VoteCount } = result;
      if (
        !topStaffPerQuestion[QuestionId] ||
        VoteCount > topStaffPerQuestion[QuestionId].VoteCount
      ) {
        topStaffPerQuestion[QuestionId] = {
          QuestionId,
          StaffId,
          StaffName,
          VoteCount,
        };
      }
    });

    const topStaff = Object.values(topStaffPerQuestion);

    return topStaff;
  };

  const columnsStation = [
    {
      Header: "Staff ID",
      accessor: "StaffId",
    },
    {
      Header: "Staff Name",
      accessor: "StaffName",
    },
    {
      Header: "Station ID",
      accessor: "StationId",
    },
    {
      Header: "Vote Counts",
      accessor: "VoteCount",
    },
  ];

  const columnsCombined = [...columnsStation];

  const columnsQuestions = [
    {
      Header: "Question ID",
      accessor: "QuestionId",
    },
    {
      Header: "Staff ID",
      accessor: "StaffId",
    },
    {
      Header: "Staff Name",
      accessor: "StaffName",
    },
    {
      Header: "Vote Counts",
      accessor: "VoteCount",
    },
  ];

  return (
    <div>
      <h2>First Table: Top Three Staff by Station</h2>
      {voteData.map((result) => (
        <div key={result.StationId}>
          <h3>Station ID: {result.StationId}</h3>
          <ReactTable
            data={calculateTopThreeByStation(result.StationId)}
            columns={columnsStation}
            defaultPageSize={3}
            showPagination={false}
          />
        </div>
      ))}

      <h2>Second Table: Top Three Staff Combined</h2>
      <ReactTable
        data={calculateTopThreeCombined()}
        columns={columnsCombined}
        defaultPageSize={3}
        showPagination={false}
      />

      <h2>Third Table: Top Staff per Question</h2>
      <ReactTable
        data={calculateTopStaffPerQuestion()}
        columns={columnsQuestions}
        defaultPageSize={10}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
    UserProfile: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnLoginDetails: (p) => {
      dispatch(setLoginDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Results);
